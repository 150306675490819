/* lora-regular - latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  src: local(""), url("../fonts/lora-v16-latin-regular.woff2") format("woff2"), url("../fonts/lora-v16-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* poppins-regular - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: local("Poppins Regular"), local("Poppins-Regular"), url("../fonts/poppins-v15-latin-regular.woff2") format("woff2"), url("../fonts/poppins-v15-latin-regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* poppins-600 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"), url("../fonts/poppins-v13-latin-600.woff2") format("woff2"), url("../fonts/poppins-v13-latin-600.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

/* poppins-800 - latin */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"), url("../fonts/poppins-v13-latin-800.woff2") format("woff2"), url("../fonts/poppins-v13-latin-800.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */ }

* {
  box-sizing: border-box; }

figure,
hr {
  margin: 0;
  text-align: center; }

img,
video {
  user-select: none; }

#color-bar,
#color-bar::after {
  visibility: hidden !important;
  position: fixed;
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: calc(6vw/ 6);
  height: 100vh;
  z-index: 999;
  transition: background-color 1000ms ease; }
  @media (min-width: 768px) {
    #color-bar,
    #color-bar::after {
      visibility: visible !important; } }

#color-bar::after {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%); }

#homepage #color-bar {
  background-color: #bd10e0; }

.color-me {
  position: absolute !important;
  align-self: center;
  height: 100%;
  max-height: 85vh;
  min-height: auto !important;
  width: 1px; }
  @media (min-width: 768px) {
    .color-me {
      max-height: 90vh; } }

::selection {
  background: #bd10e0;
  color: #fff; }

body {
  background-color: #f4f4f4; }

#storytelling h3,
#storytelling a.button,
#next #storytelling h3,
#next #storytelling a.button {
  color: #1558d4; }

#storytelling a.button,
#next #storytelling a.button {
  border: 1px solid #1558d4; }
  #storytelling a.button:hover,
  #next #storytelling a.button:hover {
    background-color: #1558d4;
    color: #fff; }

#storytelling * ::selection,
#next #storytelling * ::selection {
  background: #1558d4;
  color: #fff; }

#storytelling #intro::before,
#next #storytelling #intro::before {
  background: #1558d4; }

#storytelling .swiper-pagination-bullet-active,
#next #storytelling .swiper-pagination-bullet-active {
  background: #1558d4 !important; }

#verticals h3,
#verticals a.button,
#next #verticals h3,
#next #verticals a.button {
  color: #f84d39; }

#verticals a.button,
#next #verticals a.button {
  border: 1px solid #f84d39; }
  #verticals a.button:hover,
  #next #verticals a.button:hover {
    background-color: #f84d39;
    color: #fff; }

#verticals * ::selection,
#next #verticals * ::selection {
  background: #f84d39;
  color: #fff; }

#verticals #intro::before,
#next #verticals #intro::before {
  background: #f84d39; }

#verticals .swiper-pagination-bullet-active,
#next #verticals .swiper-pagination-bullet-active {
  background: #f84d39 !important; }

#commerce h3,
#commerce a.button,
#next #commerce h3,
#next #commerce a.button {
  color: #00a986; }

#commerce a.button,
#next #commerce a.button {
  border: 1px solid #00a986; }
  #commerce a.button:hover,
  #next #commerce a.button:hover {
    background-color: #00a986;
    color: #fff; }

#commerce * ::selection,
#next #commerce * ::selection {
  background: #00a986;
  color: #fff; }

#commerce #intro::before,
#next #commerce #intro::before {
  background: #00a986; }

#commerce .swiper-pagination-bullet-active,
#next #commerce .swiper-pagination-bullet-active {
  background: #00a986 !important; }

#video h3,
#video a.button,
#next #video h3,
#next #video a.button {
  color: #349ae4; }

#video a.button,
#next #video a.button {
  border: 1px solid #349ae4; }
  #video a.button:hover,
  #next #video a.button:hover {
    background-color: #349ae4;
    color: #fff; }

#video * ::selection,
#next #video * ::selection {
  background: #349ae4;
  color: #fff; }

#video #intro::before,
#next #video #intro::before {
  background: #349ae4; }

#video .swiper-pagination-bullet-active,
#next #video .swiper-pagination-bullet-active {
  background: #349ae4 !important; }

#design-system h3,
#design-system a.button,
#next #design-system h3,
#next #design-system a.button {
  color: #fb5c86; }

#design-system a.button,
#next #design-system a.button {
  border: 1px solid #fb5c86; }
  #design-system a.button:hover,
  #next #design-system a.button:hover {
    background-color: #fb5c86;
    color: #fff; }

#design-system * ::selection,
#next #design-system * ::selection {
  background: #fb5c86;
  color: #fff; }

#design-system #intro::before,
#next #design-system #intro::before {
  background: #fb5c86; }

#design-system .swiper-pagination-bullet-active,
#next #design-system .swiper-pagination-bullet-active {
  background: #fb5c86 !important; }

#bio h3,
#bio a.button,
#brands h3,
#brands a.button {
  color: #bd10e0; }

#bio a.button,
#brands a.button {
  border: 1px solid #bd10e0; }
  #bio a.button:hover,
  #brands a.button:hover {
    background-color: #bd10e0;
    color: #fff; }

#bio * ::selection,
#brands * ::selection {
  background: #bd10e0;
  color: #fff; }

#bio #intro::before,
#brands #intro::before {
  background: #bd10e0; }

#bio .swiper-pagination-bullet-active,
#brands .swiper-pagination-bullet-active {
  background: #bd10e0 !important; }

#homepage #intro {
  color: #333;
  z-index: auto; }
  #homepage #intro::after {
    background: none; }
  #homepage #intro h1 {
    color: #bd10e0; }

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-repeat: no-repeat;
  background-color: white;
  background-position: center; }

.no-scroll {
  height: 100vh;
  overflow: hidden; }

.transition-fade {
  transition: 0.7s;
  opacity: 1; }

html.is-animating .transition-fade {
  opacity: 0; }

html.sr .load-hidden {
  visibility: hidden; }

body,
html {
  font-family: "Poppins", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  color: #333;
  line-height: 1.5; }

h1,
h2,
h3,
h4 {
  margin: 0.5em 0;
  font-weight: 800; }

h1 {
  font-size: 2.75rem;
  line-height: 1.25;
  letter-spacing: -0.2rem;
  word-spacing: 0.2rem;
  margin-top: 10rem; }
  @media (min-width: 768px) {
    h1 {
      font-size: 4rem;
      line-height: 1.2; } }
  @media (min-width: 992px) {
    h1 {
      font-size: 4.5rem;
      line-height: 1.15; } }
  @media (min-width: 1200px) {
    h1 {
      font-size: 5.5rem;
      line-height: 1.1; } }

h1 span {
  color: #333; }

h2 {
  font-size: 1.25rem;
  font-weight: 400;
  font-family: "Lora", serif;
  margin-bottom: 4rem; }
  @media (min-width: 768px) {
    h2 {
      margin-right: 13%;
      font-size: 1.25rem;
      margin-bottom: 6vw; } }
  @media (min-width: 992px) {
    h2 {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    h2 {
      font-size: 1.5rem; } }

h3 {
  font-size: 2.25rem;
  font-weight: 800;
  line-height: 1.4;
  letter-spacing: -0.085rem;
  word-spacing: 0.2rem; }
  @media (min-width: 768px) {
    h3 {
      font-size: 2.25rem;
      letter-spacing: -0.085rem; } }
  @media (min-width: 992px) {
    h3 {
      font-size: 2.5rem;
      line-height: 1.3;
      letter-spacing: -0.1rem; } }
  @media (min-width: 1200px) {
    h3 {
      font-size: 3rem;
      line-height: 1.25;
      letter-spacing: -0.15rem; } }
  h3 span {
    color: #bd10e0; }

p {
  font-size: 1.125rem;
  font-family: "Lora", serif;
  margin: 0 0 1.5em 0; }

a {
  color: #bd10e0;
  width: fit-content;
  text-decoration: none;
  border-bottom: 1px solid #bd10e0;
  padding-bottom: 2px; }

a:hover {
  cursor: pointer;
  opacity: 0.5; }

a.button {
  display: inline-block;
  padding: 1rem 2rem;
  margin: 2rem 0;
  border-radius: 500px;
  border: 1px solid #bd10e0;
  color: #bd10e0;
  text-transform: uppercase;
  font-size: 0.8em;
  letter-spacing: 0.2rem;
  font-weight: 600;
  transition-duration: 0.4s;
  position: relative; }

a:hover.button {
  background-color: #bd10e0;
  color: #fff;
  transition-duration: 0.4s;
  cursor: pointer; }

a.button.disabled {
  color: #333 !important;
  cursor: not-allowed; }

a:hover.button.disabled {
  background-color: transparent !important;
  border-color: #ddd !important; }

figcaption {
  margin-top: 2rem;
  font-family: "Lora", serif;
  color: #333;
  font-size: 0.875em;
  font-style: italic; }
  figcaption span.credit {
    font-weight: 600;
    display: inline-block; }

hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: #ddd; }

video:hover {
  cursor: pointer;
  cursor: url(/img/pause-ico.svg) 0 0, pointer; }

main section.grid .project-desc hr {
  visibility: hidden !important; }
  @media (min-width: 768px) {
    main section.grid .project-desc hr {
      visibility: visible !important; } }

main div.section-wrap:first-child {
  margin-bottom: 2rem; }
  @media (min-width: 768px) {
    main div.section-wrap:first-child {
      margin-bottom: 4rem; } }

#site-wrap {
  width: 100vw;
  overflow-x: hidden; }

.section-wrap {
  padding: 0 6vw; }

.section-wrap-inner {
  max-width: 1200px;
  margin: 0 auto; }

section {
  padding: 2rem 0; }
  @media (min-width: 768px) {
    section {
      padding: 6vw 0; } }
  section#intro {
    padding: 0; }

section.grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr)); }
  @media (min-width: 768px) {
    section.grid {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: calc(6vw * 2); } }

section.grid div {
  grid-column: span 2;
  position: relative;
  display: grid;
  align-content: center;
  align-items: center;
  min-height: 400px; }
  @media (min-width: 768px) {
    section.grid div {
      grid-column: span 1;
      min-height: 500px; } }

section.grid.project > div img {
  position: absolute; }

@media (min-width: 768px) {
  section.grid:nth-child(even) .project-img {
    justify-items: end;
    justify-content: end; }
  section.grid:nth-child(odd) .project-img {
    justify-items: start;
    justify-content: start; } }

section.grid .project-desc {
  margin-top: 6vw;
  min-height: auto; }
  @media (min-width: 768px) {
    section.grid .project-desc {
      margin-top: 0; } }
  section.grid .project-desc hr {
    margin-top: 6vw; }

section.grid .project-img {
  max-width: 400px;
  width: stretch;
  margin: 0 auto; }
  @media (min-width: 768px) {
    section.grid .project-img {
      max-width: none; } }

@media (min-width: 768px) {
  section .gridSwap {
    grid-row: 1;
    grid-column: 1; } }

.isometric {
  transform: rotateX(45deg) rotateY(0deg) rotateZ(-45deg); }

@media (min-width: 768px) {
  .project.odd .isometric {
    transform-origin: 0 0;
    top: 50%;
    /*margin-top: 50%;
    margin-bottom: -50%;*/ }
  .project.even .isometric {
    transform-origin: 100% 100%;
    bottom: 50%;
    /*margin-top: -50%;
    margin-bottom: 50%;*/ } }

.swiper-container {
  overflow: visible !important; }

.swiper-wrapper {
  margin: 0 !important; }

@media (max-width: 767px) {
  .swiper-slide figure {
    opacity: 1 !important; } }

.swiper-pagination {
  position: absolute !important;
  margin: -3vw 0 6vw !important;
  position: inherit !important; }
  @media (min-width: 768px) {
    .swiper-pagination {
      display: none; } }

.swiper-pagination-bullet {
  opacity: 1 !important;
  background-color: #ddd !important; }

.project-img video,
.project-img img {
  border-radius: 10px;
  overflow: hidden; }

header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000; }

nav ul,
footer ul {
  padding: 0;
  margin: 4rem 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }

nav li,
footer li {
  list-style: none;
  margin-right: 2em; }
  nav li:last-child,
  footer li:last-child {
    margin-right: 0; }

nav a,
footer a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.8em;
  letter-spacing: 0.1em;
  color: #fff;
  border: 0;
  padding: 0;
  text-transform: uppercase;
  transition: color 2s, opacity 0.5s; }
  nav a:hover,
  footer a:hover {
    opacity: 0.5; }

nav li:first-child,
footer li:first-child {
  margin-right: auto;
  margin-left: 0; }
  nav li:first-child a,
  footer li:first-child a {
    text-transform: none;
    padding: 0.5em;
    font-size: 1em;
    border: 4px solid;
    border-image-slice: 1;
    border-color: #bd10e0; }

footer a {
  color: #fff; }

footer small {
  display: block;
  text-align: center;
  color: #ddd;
  margin: 1rem 0;
  padding: 2rem 0 1rem 0;
  border-top: 1px solid #333; }
  @media (min-width: 768px) {
    footer small {
      text-align: left; } }

header.homepage nav a {
  color: #333; }

section#storytelling .project-img {
  top: 10%; }
  @media (min-width: 768px) {
    section#storytelling .project-img {
      top: 50%; } }

section#storytelling .project-img video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-width: 100vh;
  box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.06), -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06); }
  @media (min-width: 768px) {
    section#storytelling .project-img video {
      width: 500px;
      max-width: none;
      box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06), -64px 64px 32px rgba(0, 0, 0, 0.06), -128px 128px 64px rgba(0, 0, 0, 0.06); } }

section#storytelling .project-img img:first-of-type {
  width: 100%;
  height: auto;
  top: 200px;
  left: 0; }
  @media (min-width: 768px) {
    section#storytelling .project-img img:first-of-type {
      width: 500px;
      top: 300px;
      left: -5%; } }

section#storytelling .project-img img:last-of-type {
  width: 40%;
  height: auto;
  max-width: 40vh;
  top: 10%;
  left: 80%; }
  @media (min-width: 768px) {
    section#storytelling .project-img img:last-of-type {
      width: 250px;
      height: auto;
      top: 20%;
      left: 80%; } }

.mobile-comp {
  height: 100%;
  width: auto;
  top: 0;
  position: relative; }
  @media (min-width: 768px) {
    .mobile-comp {
      height: 500px; } }

section#verticals .project-img img:first-of-type {
  top: -20%;
  right: 60%; }

section#verticals .project-img img:nth-of-type(2) {
  top: 0%;
  right: 35%;
  box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.08), -8px 8px 4px rgba(0, 0, 0, 0.08), -16px 16px 8px rgba(0, 0, 0, 0.08); }

section#verticals .project-img img:last-of-type {
  top: 20%;
  right: 10%;
  box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.08), -8px 8px 4px rgba(0, 0, 0, 0.08), -16px 16px 8px rgba(0, 0, 0, 0.08), -32px 32px 16px rgba(0, 0, 0, 0.08), -64px 64px 32px rgba(0, 0, 0, 0.08); }

@media (min-width: 768px) {
  section#verticals .project-img img:first-of-type {
    right: -20%;
    bottom: 20%;
    top: auto; }
  section#verticals .project-img img:nth-of-type(2) {
    right: 10%;
    bottom: 0%;
    top: auto;
    box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.08), -8px 8px 4px rgba(0, 0, 0, 0.08), -16px 16px 8px rgba(0, 0, 0, 0.08), -32px 32px 16px rgba(0, 0, 0, 0.08), -64px 64px 32px rgba(0, 0, 0, 0.08); }
  section#verticals .project-img img:last-of-type {
    right: 40%;
    bottom: -20%;
    top: auto;
    box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06), -64px 64px 32px rgba(0, 0, 0, 0.06), -128px 128px 64px rgba(0, 0, 0, 0.06); } }

section#commerce .project-img img {
  width: 100%;
  height: auto;
  max-width: 100vh;
  top: 0;
  left: 0; }
  @media (min-width: 768px) {
    section#commerce .project-img img {
      width: 400px; } }
  section#commerce .project-img img:first-child {
    box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06), -64px 64px 32px rgba(0, 0, 0, 0.06), -128px 128px 64px rgba(0, 0, 0, 0.06); }
  section#commerce .project-img img:nth-child(2) {
    top: 15%;
    left: 5%;
    box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.06), -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06); }
  section#commerce .project-img img:nth-child(3) {
    width: 25%;
    height: auto;
    left: 100%;
    top: -15%; }
    @media (min-width: 768px) {
      section#commerce .project-img img:nth-child(3) {
        width: 130px;
        left: 30%;
        top: -15%; } }
  section#commerce .project-img img:last-child {
    width: 30%;
    height: auto;
    left: 50%;
    top: -15%; }
    @media (min-width: 768px) {
      section#commerce .project-img img:last-child {
        width: 150px;
        left: 70%;
        top: -15%; } }

section#video .project-img * {
  width: 100%;
  height: auto;
  max-width: 100vh; }
  @media (min-width: 768px) {
    section#video .project-img * {
      width: 500px; } }

section#video .project-img video {
  position: absolute;
  top: 10%;
  left: -20%;
  box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06), -64px 64px 32px rgba(0, 0, 0, 0.06), -128px 128px 64px rgba(0, 0, 0, 0.06); }
  @media (min-width: 768px) {
    section#video .project-img video {
      bottom: 0;
      right: 0;
      top: auto;
      left: auto; } }

section#video .project-img img:first-of-type {
  top: 25%;
  left: 0%; }

section#video .project-img img:last-of-type {
  top: 45%;
  left: 25%; }

@media (min-width: 768px) {
  section#video .project-img img:first-of-type {
    bottom: 20%;
    right: -20%;
    top: auto;
    left: auto; }
  section#video .project-img img:last-of-type {
    bottom: 40%;
    right: -40%;
    top: auto;
    left: auto; } }

section#design-system .project-img img {
  image-rendering: -webkit-optimize-contrast;
  top: 0;
  left: 0;
  height: 100%;
  width: auto;
  max-height: 100vw; }
  section#design-system .project-img img:first-child {
    box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06), -64px 64px 32px rgba(0, 0, 0, 0.06), -128px 128px 64px rgba(0, 0, 0, 0.06); }
  @media (min-width: 768px) {
    section#design-system .project-img img {
      top: 0;
      left: 0;
      width: 500px;
      height: auto; } }

div#about,
footer {
  background-color: #252525;
  color: #ddd;
  padding-bottom: 1px; }
  div#about hr,
  footer hr {
    background-color: #333; }

div#about img {
  max-height: 50vh;
  width: auto; }
  @media (min-width: 768px) {
    div#about img {
      max-height: 500px; } }

div#about .grid {
  align-items: center; }

section#more {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 6vw;
  margin-top: 0;
  padding-top: 0; }
  @media (min-width: 768px) {
    section#more {
      grid-template-columns: repeat(3, minmax(0, 1fr)); } }
  section#more div {
    min-height: auto;
    align-self: start;
    border-top: 1px solid #333;
    padding: 6vw 0; }
    @media (min-width: 768px) {
      section#more div {
        padding-bottom: 0; } }
  section#more h3 {
    font-size: 1.5em;
    color: #bd10e0;
    margin: 0;
    letter-spacing: -0.025em; }
  section#more ul {
    padding: 0;
    margin-bottom: 0;
    font-size: 1.25rem;
    font-family: "Lora", serif; }
  section#more li {
    list-style: none;
    display: block;
    margin-bottom: 0.2em; }
    section#more li::after {
      content: " ,";
      color: #bd10e0; }
    section#more li:last-child::after {
      content: none; }

#intro {
  color: #fff;
  position: relative;
  min-height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  padding-bottom: 0;
  z-index: -1; }
  #intro::before, #intro::after {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    z-index: -2; }
  #intro::after {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%); }
  @media (min-width: 768px) {
    #intro {
      padding-bottom: 6vw; } }
  #intro hr {
    visibility: visible !important; }
  #intro div.section-wrap {
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    min-height: 100vh; }
  @media (min-width: 768px) {
    #intro div.section-wrap-inner {
      padding-right: 20%; } }
  #intro figure {
    visibility: hidden;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: -1; }
    @media (min-width: 768px) {
      #intro figure {
        min-width: 992px;
        visibility: visible; } }
    #intro figure img {
      position: absolute;
      top: 0%;
      right: 0%;
      max-height: 50vh; }
      @media (min-width: 768px) {
        #intro figure img {
          top: 45vh;
          left: 80%; } }

@media (min-width: 768px) {
  .case-study p {
    margin-right: 33%; } }

.case-study figure {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative; }
  @media (min-width: 768px) {
    .case-study figure {
      flex-wrap: nowrap; } }
  .case-study figure div {
    margin: 6vw 0 12vw 0;
    position: relative; }
    @media (min-width: 768px) {
      .case-study figure div {
        flex: 1 1 0;
        margin: 6vw 2vw; } }
    .case-study figure div img,
    .case-study figure div video {
      max-width: 100%;
      width: auto;
      max-height: 812px;
      height: auto;
      margin: 0 auto;
      border-radius: 10px;
      box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.06), -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06); }
      @media (min-width: 768px) {
        .case-study figure div img,
        .case-study figure div video {
          box-shadow: -4px 4px 2px rgba(0, 0, 0, 0.06), -8px 8px 4px rgba(0, 0, 0, 0.06), -16px 16px 8px rgba(0, 0, 0, 0.06), -32px 32px 16px rgba(0, 0, 0, 0.06), -64px 64px 32px rgba(0, 0, 0, 0.06), -128px 128px 64px rgba(0, 0, 0, 0.06);
          max-height: 812px; } }
    .case-study figure div figure {
      display: block;
      position: relative;
      height: 100%;
      width: 100%; }
    .case-study figure div.full-page {
      min-width: 100vw;
      height: 70vh;
      min-height: 300px;
      overflow: hidden;
      background-color: #ddd; }
      @media (min-width: 768px) {
        .case-study figure div.full-page {
          height: 800px;
          min-height: initial; } }
      .case-study figure div.full-page img {
        max-height: none;
        max-width: 100vw;
        position: absolute;
        box-shadow: none; }
        @media (min-width: 768px) {
          .case-study figure div.full-page img {
            max-width: 600px; } }
        .case-study figure div.full-page img:first-child {
          right: 40%;
          top: 0%;
          opacity: 0.85; }
          @media (min-width: 768px) {
            .case-study figure div.full-page img:first-child {
              left: 15%;
              right: auto; } }
        .case-study figure div.full-page img:last-child {
          left: 40%;
          top: -5%; }
          @media (min-width: 768px) {
            .case-study figure div.full-page img:last-child {
              right: 15%;
              left: auto; } }
    .case-study figure div div.mweb-video {
      position: absolute;
      max-width: 375px;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto;
      overflow: hidden;
      box-shadow: none;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      border-radius: 10px 10px 0 0;
      transform: translateZ(0); }
      .case-study figure div div.mweb-video video {
        width: 100%;
        position: relative;
        border-radius: 10px 10px 0 0; }
  .case-study figure.feature div.mweb-video video {
    height: 100%;
    width: auto;
    max-width: none; }
  .case-study figure.feature figure {
    display: block;
    max-width: 375px;
    margin: 0 auto; }
    .case-study figure.feature figure div {
      height: 59%; }
  .case-study figure.feature video {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: none; }

.case-study section#write-up {
  padding: 0; }

.case-study section#next .project-img figure * {
  opacity: 1 !important;
  visibility: visible !important; }

.case-study section#next {
  background-color: #ddd;
  margin-top: 6vw; }
  .case-study section#next figure {
    position: initial; }
  .case-study section#next p {
    margin-right: 0; }
  .case-study section#next div.section-wrap:first-child {
    margin-bottom: 0; }

.case-study ul.details {
  padding: 0;
  margin: 2em 0 6vw 0;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; }
  @media (min-width: 768px) {
    .case-study ul.details {
      flex-wrap: nowrap; } }
  .case-study ul.details li {
    list-style: none;
    margin: 0 6vw 2em 0;
    min-width: 5em;
    font-family: "Lora", serif;
    font-size: 0.875em; }
  .case-study ul.details li span {
    text-transform: uppercase;
    display: block;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    font-weight: 600;
    margin-bottom: 0.5em;
    font-family: "Poppins", sans-serif; }
